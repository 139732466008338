import { Route, Routes } from 'react-router-dom';
import { HVAC } from './loadables';
import { getDBelApiCredentials } from '@dbel/react-commons/api';
import i18n from 'i18next';
import { AllWidgetsLayout } from '../pages/AllWidgetsLayout';

export const WidgetRoutes = () => {
  const apiCredential = getDBelApiCredentials();

  return (
    <Routes>
      <Route element={<AllWidgetsLayout />}>
        <Route
          path="stiebel/*"
          element={
            <HVAC
              apiCredentials={{
                wsEndpoint: apiCredential.wsEndpoint,
                apiEndpoint: apiCredential.apiEndpoint,
              }}
              language={i18n.language}
            />
          }
        />
        <Route
          path="tecalor/*"
          element={
            <HVAC
              apiCredentials={{
                wsEndpoint: apiCredential.wsEndpoint,
                apiEndpoint: apiCredential.apiEndpoint,
              }}
              language={i18n.language}
            />
          }
        />
      </Route>
      <Route path="*" element={<>Not found </>} />
    </Routes>
  );
};
